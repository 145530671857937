import React from 'react'
import SbEditable from 'storyblok-react'

const Video = (props) => (
  <SbEditable content={props.blok}>
    {props.blok.file ? <video src={props.blok.file} loop={true} autoPlay={true} muted={true} width="100%"/> :
      
      <div style={{
        width: "100%",
        height: "0",
        position: "relative",
        boxSizing: "content-box",
        paddingBottom: "56.25%",
        margin: "2rem auto"
      }}>

      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0
        }}
        src={ (props.blok.provider === "vimeo" ? 'https://player.vimeo.com/video/' : "https://www.youtube-nocookie.com/embed/") + props.blok.ID}
        width="100%" 
        height="100%" 
        frameBorder="0" 
        allow={ props.blok.provider === "vimeo" ? "autoplay; fullscreen"  : "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
        allowFullScreen
        > 
      </iframe>
    </div>
    }
  </SbEditable>
)

export default Video