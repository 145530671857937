import React from 'react'
import Components from './components.js';
import SbEditable from 'storyblok-react'

const Grid = (props) => (
  <SbEditable content={props.blok}>
    <div className={ "container " + props.blok.className}
      style={{
        marginTop: props.blok.marginTop,
        marginBottom: props.blok.marginBottom
      }}>
      <div className={props.blok.fullwidth ? "grid-row fullwidth" : "grid-row"} >
        {props.blok.columns.map((blok) =>
          React.createElement(Components(blok.component), {key: blok._uid, blok: blok})
        )}
      </div>
    </div>
  </SbEditable>
)

export default Grid