import React from "react"
import SbEditable from 'storyblok-react'



const Image = (props) => (

  <SbEditable content={props.blok}>

    {console.log(props.blok.image)}
    <img 
      style={{
        margin: "0 " + (props.blok.align === "left" ? "0" : "auto") + " 0 " + (props.blok.align === "right" ? "0" : "auto"),
        display: "block"
      }} 
      alt={props.blok.alt}
      
      

      src={props.blok.image} 
      width={props.blok.width} 
      className="content-img"
    />
  </SbEditable>
)

export default Image
