import React from 'react'
import { motion } from 'framer-motion'

const lineAnim = {
    hidden: { scaleX: 0, orginX: 0},
    visible: { scaleX: 1,orginX: 0, delay: 0.5},
    exit: { scaleX: 0, orginX: 0}
}

const variants = {
    visible: {
      opacity: 1,
      y: "0px",
      scale: 1,
    },
    hidden: {
      opacity: 0,
      y: "24px",
      scale: 1.05,
    },
    exit: {
      opacity: 0,
      y: "-24px",
      scale: 0.95,
    },
  }


const AboutEntry = (props) => (
    <motion.div variants={variants} className={props.blok.percentage  !== "" ? "about-entry progress" : "about-entry"}>
        <div className="about-entry-header">
            <h3 className="about-entry-title">{props.blok.title}</h3>

            <motion.div variants={lineAnim} className="line"/>

            {props.blok.percentage !== "" &&(
                <div className="line-background">
                    <div className="line-percentage" style={{width: props.blok.percentage + "%"}}/>
                </div>
            )}

            {props.blok.date !== "" &&(
                <span className="about-entry-date" >{props.blok.date}</span>
            )}

        </div>    
        <h4 className="about-entry-info">{props.blok.info}</h4>
    </motion.div> 
)

export default AboutEntry
