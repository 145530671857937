import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from './richText.js';
import { motion } from 'framer-motion';

const fade = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
}

const Text = (props) => (
  <SbEditable content={props.blok}>

    <motion.div variants={fade} className="content-text">
        <RichText richtext={props.blok.text} />
    </motion.div>
  </SbEditable>
)

export default Text