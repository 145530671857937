import React from 'react'
import Components from './components.js';
import SbEditable from 'storyblok-react'
import AnimWrapper from './animWrapper.js';
import { motion } from 'framer-motion';

const fade = {
  hidden: { opacity: 0 },
  visible: { opacity: 1},
  exit: { opacity: 0 }
}

const variants = {
    visible: {
      opacity: 1,
      y: "0px",
      scale: 1,
      transition: {
        duration: 0.1,
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      y: "24px",
      scale: 1.05,
      transition: {
        when: "afterChildren",
      },
    },
    exit: {
      opacity: 0,
      y: "-24px",
      scale: 0.95,
      transition: {
        when: "afterChildren",
        duration: 0.1,
        staggerChildren: 0.05,
      },
    },
  }

const AboutSection = (props) => (
    <section className="about-section">
        
        <motion.h2 variants={fade}> {props.blok.type}</motion.h2>
                
                    <AnimWrapper variants={variants} className="about-entries">
                    
                        <SbEditable content={props.blok}>
                            {props.blok.entries && props.blok.entries.map((blok) => React.createElement(Components(blok.component), {key: blok._uid, blok: blok}))}
                        </SbEditable>
                    </AnimWrapper>
                
    </section> 
)

export default AboutSection
