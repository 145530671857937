import React from 'react'
import RichText from './richText'
import { motion } from 'framer-motion'

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1},
  exit: { opacity: 0 }
}

const AboutInfo = (props) => (
  <motion.div variants={variants} className="about-container">
    <header className="about-header">
    <div className="about-img">
        <img alt="avatar" className="avatar" src="https://res.cloudinary.com/altreiter/image/upload/c_scale,q_auto:good,w_320/v1590314446/about/bewerbungsbild-small_rm1ief.jpg"></img>
    </div>
    <RichText className="about-title" richtext={props.blok.info_title}/>
  </header>

  <RichText className="about-text" richtext={props.blok.info_text}/>
</motion.div>
)

export default AboutInfo
