import React from 'react'
import AnimWrapper from './animWrapper'

function getTransform(direction){
    switch (direction) {
      case "top":
        return "rotate(-90deg)"
        break
      case "left":
        return "rotate(180deg)"
        break
      case "bottom":
        return "rotate(90deg)"
        break
      default: return "none"
    }
  }

const BigButton = (props) => (
  <AnimWrapper variants={{
    hidden: { opacity: 0 },
    visible: { opacity: 1},
    exit: { opacity: 0 }
  }}>
    <a 
        className="bigbutton"
        href={props.blok.link || props.blok.file}
        target={props.blok.blank ? "_blank" : "_self"}
        download={props.blok.download}
        >
        {props.blok.text}
    </a>
    </AnimWrapper>
)

export default BigButton
