import Page from './page'
import Grid from './grid'
import Header from './header'
import WorkEntry from './workEntry'
import WorksOverview from './worksOverview'
import AboutInfo from './aboutInfo'
import AboutEntry from './aboutEntry'
import AboutSection from './aboutSection'
import AboutTeaser from './aboutTeaser'
import Text from './text'
import Video from './video'
import Image from './image'
import BigButton from './bigButton'
import Headline from './headline'

import ComponentNotFound from './component_not_found'

const ComponentList = {
  page: Page,
  grid: Grid,
  header: Header,
  workEntry: WorkEntry,
  worksOverview: WorksOverview,
  aboutInfo: AboutInfo,
  aboutEntry: AboutEntry,
  aboutSection: AboutSection,
  aboutTeaser: AboutTeaser,
  text: Text,
  video: Video,
  image: Image,
  bigButton: BigButton,
  headline: Headline,
  
}

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components