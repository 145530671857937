import React from 'react'
import StoryblokClient from 'storyblok-js-client'

let Storyblok = new StoryblokClient({
    accessToken: 'xNfmB9yQZh1fpkNizUJS7Qtt'
})

function createMarkup(text){
    return {__html: Storyblok.richTextResolver.render(text)};
}

const RichText = (props) => (
    <div dangerouslySetInnerHTML={createMarkup(props.richtext)} className={props.className}/>
)

export default RichText
