import React from 'react'
import Components from './components.js';
import Layout from "./layout"
import BigLink from './bigLink.js';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby'
import Category from './category.js';
import AnimWrapper from './animWrapper.js';
import { TransitionState } from "gatsby-plugin-transition-link"

const onlyOut = {
  hidden: { opacity: 1 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
}

const fade = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
}


const variants = {
  visible: {
    opacity: 1,
    y: "0px",
    scale: 1,
    transition: {
      duration: 0.2,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    y: "24px",
    scale: 1.05,
    transition: {
      when: "afterChildren",
    },
  },
  exit: {
    opacity: 0,
    y: "-24px",
    scale: 0.95,
    transition: {
      when: "afterChildren",
      duration: 0.1,
      staggerChildren: 0.05,
    },
  },
}


const WorkEntry = (props) => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allStoryblokEntry(
          filter: {field_component: {eq: "workEntry"}},
          sort: {fields: position, order: ASC}
          ) {
          edges {
            node {
              field_title_string
              full_slug
            }
          }
        }
      }
    `)
  const entries = data.allStoryblokEntry.edges

  const currentIndex = entries.indexOf(
    entries.find(entry => props.slug === entry.node.full_slug)
  )
  const prevIndex = (currentIndex > 0 ? currentIndex - 1 : entries.length - 1)

  const nextIndex = (currentIndex < entries.length - 1 ? currentIndex + 1 : 0)


  return (

    <Layout title={props.blok.title} slug={props.slug} cats={props.blok.categories} description={props.blok.description}>
      <motion.header variants={fade} className="works-header">
        <BigLink to={'/works/'} direction="left" > show all works </BigLink>
      </motion.header>

      <TransitionState>

        {({ entry }) => {
          return(          
            <div className="work-entry-thumbnail solo">
              <motion.img 
                variants={entry.state.type === "outOnly" ? onlyOut : fade} 
                alt={props.blok.title} 
                src={props.blok.thumbnail ? props.blok.thumbnail : "https://placekitten.com/1152/604"} >
              </motion.img>
            </div>
          )
        }}
      </TransitionState>
      <AnimWrapper variants={variants}>
        {props.blok.body && props.blok.body.map((blok, index) => (
          <motion.div key={index} variants={variants}>
            {React.createElement(Components(blok.component), { key: blok._uid, blok: blok })}
          </motion.div>
        ))}

        <motion.div className="work-entry-facts" variants={fade}>
          <ul>
            <li key="title"> {props.blok.title}  - { new Date(props.blok.date).getFullYear() } </li>
            <li key="description"> {props.blok.description}   </li>
            <li key="categories">
              {props.blok.categories.map((cat, index) => (
                  <span key={index}>
                    <Category key={index} keyVal={index} category={cat} textOnly={true} />
                    {index < props.blok.categories.length - 1 && ", " }
                  </span>

              ))}
            </li>
            <li key="info"> {props.blok.info}  </li>
          </ul>
        </motion.div>


        <motion.div variants={fade} className="works-more">
          <h2>Take a look at other Works!</h2>
          <nav>
            <BigLink state="fade" to={'/' + entries[prevIndex].node.full_slug} direction="left"> {entries[prevIndex].node.field_title_string}  </BigLink>
            <BigLink state="fade" to={'/' + entries[nextIndex].node.full_slug} position="right"> {entries[nextIndex].node.field_title_string}  </BigLink>
          </nav>
        </motion.div>
      </AnimWrapper>

    </Layout>
  )

}





export default WorkEntry